export const XLDeployConfiguration = {
    APP_SG_CONNECT_ENDPOINT: '%#APP_SG_CONNECT_ENDPOINT#%',
    APP_SG_CONNECT_CLIENT_ID: '%#APP_SG_CONNECT_CLIENT_ID#%',
    APP_SG_CONNECT_SCOPE: '%#APP_SG_CONNECT_SCOPE#%',
    APP_SG_CONNECT_LEVEL: '%#APP_SG_CONNECT_LEVEL#%',
    APP_SG_CONNECT_STRONG_LEVEL: '%#APP_SG_CONNECT_STRONG_LEVEL#%',
    gcuCashFrontApiUrl: '%#CashFrontApiServerUrl#%',
    gcuHabilitationFrontApiUrl: '%#HabilitationFrontApiServerUrl#%',
    gcuTaxFrontApiUrl: '%#TaxFrontApiServerUrl#%',
    gcuTaxFrontAddVideoUrl: '%#TaxFrontApiAddVideoServerUrl#%',
    taxPlatformReclaimFrontApiUrl: '%#taxPlatformReclaimFrontApiUrl#%',
    gcuCorporateActionFrontApiUrl: '%#CorporateActionFrontApiServerUrl#%',
    gcuMutualFundFrontApiUrl: '%#MFMonitoringFrontApiServerUrl#%',
    gcuFrontApiUrl: '/suite2',
    piwikBase: '%#PIWIKTracker#%',
    piwikId: '%#PIWIKsiteId#%',
    gcuScadFrontApiUrl: '%#ScadFrontApiServerUrl#%',
    gcuScadApiUrl: '%#ScadApiServerUrl#%',
    gcuSecuritiesFrontApiUrl: '%#SecuritiesFrontApiServerUrl#%',
    gcuSubscriptionFrontApiUrl: '%#SubscriptionFrontApiServerUrl#%',
    gcuCorporateActionsCalendarWidgetUrl: '%#CorporateActionsCalendarWidgetUrl#%',
    corporateActionsDateFrom: undefined,
    gcuContractnotesFrontApiUrl: '%#ContractNotesFrontApiServerUrl#%',
    gcuReferentialFrontApiUrl: '%#ReferentialFrontApiServerUrl#%',
    gcuSettlementInstructionFrontApiUrl: '%#CustodySettlementInstructionFrontApiServerUrl#%',
    gcuUserFrontApiUrl: '%#gcuUserFrontApiUrl#%',
    gcuPortfolioApiUrl: '%#gcuPortfolioApiUrl#%',
    gcuBaseUrl: '%#HostBaseUrl#%',
    gcuForexBaseUrl: '%#gcuForexBaseUrl#%',
    gcuEcashBaseUrl: '%#gcuEcashBaseUrl#%',
    gcuHabilitationBaseUrl: '%#gcuHabilitationBaseUrl#%',
    gcuSubscriptionBaseUrl: '%#gcuSubscriptionBaseUrl#%',
    gcuFastnetBaseUrl: '%#gcuFastnetBaseUrl#%',
    gcuCorporateActionsBaseUrl: '%#gcuCorporateActionsBaseUrl#%',
    isMSMQEnabledValue: '%#EnableMSMQ#%',
    gcuEsettlementBaseUrl: '%#gcuEsettlementBaseUrl#%',
    isMSMQEnabled(): boolean {
        // @ts-ignore XLDeploy placeholder can't be put as a boolean
        return this.isMSMQEnabledValue === 'true';
    },
    isfeedbackOptionEnabled: '%#EnableFeedback#%',
    isFeedbackEnabled(): boolean {
        return this.isfeedbackOptionEnabled === 'true';
    },
    isScadApiEnabled: '%#EnableSCAD#%',
    isScadApiEnable(): boolean {
        // @ts-ignore XLDeploy placeholder can't be put as a boolean
        return this.isScadApiEnabled === 'true';
    },

    isLegacySettlementOptionEnabled: '%#EnableSettlementLegacy#%',
    isLegacySettlementEnabled(): boolean {
        return this.isLegacySettlementOptionEnabled === 'true';
    },
    gcuESettlementApiUrl: '%#EsettlementApiServerUrl#%',
    gcuESettlementReplayUrl: '%#EsettlementReplayApiServerUrl#%',
    custodyOneUrl: '%#custodyOneUrl#%',
    helpcenter_topic_id_documents: '%#helpcenterTopicId#%',
};
